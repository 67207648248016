import { useState, useLayoutEffect } from 'react'

import allImages from '../images'
import { getRandomIndex, getRandomUniqueImage, getRandomImages } from '../utils'


const DELAY_CHANGE_IMAGE_MS = 1500
const COUNT_OF_SHOWED_IMAGES = 20

export const useImageRotator = (isActive = false) => {
  const [ randomImages, setRandomImages ] = useState([])

  useLayoutEffect(() => {
    const newRandomImages = getRandomImages(allImages, COUNT_OF_SHOWED_IMAGES)

    setRandomImages(newRandomImages)
  }, [])

  useLayoutEffect(() => {
    const interval = setInterval(() => {
      if (!isActive) {
        return
      }

      setRandomImages((prevImages) => {
        const randomIndex = getRandomIndex(prevImages.length)

        return prevImages.map((prevImage, idx) =>
          idx === randomIndex ? getRandomUniqueImage(allImages, prevImages) : prevImage
        )
      })
    }, DELAY_CHANGE_IMAGE_MS)

    return () => clearInterval(interval)
  }, [ isActive ])

  return randomImages
}
