import React, { useCallback } from 'react'
import { useDevice } from 'device'
import { openModal } from 'modals'

import { Tooltip } from 'components/feedback'


export type ExplanationProps = {
  children: React.ReactElement
  title: string | Intl.Message
  text: string | Intl.Message
  'data-testid'?: string
  onShow?: () => void
}

const Explanation: React.FunctionComponent<ExplanationProps> = (props) => {
  const { children, title, text, 'data-testid': dataTestId, onShow } = props

  const { isMobile } = useDevice()

  const handleClick = useCallback(() => {
    if (isMobile) {
      openModal('commonDrawerModal', {
        title,
        text,
        'data-testid': 'explanation',
      })
    }

    if (typeof onShow === 'function') {
      onShow()
    }
  }, [ isMobile, title, text, onShow ])

  if (isMobile) {
    return (
      <button
        type="button"
        onClick={handleClick}
        data-testid={dataTestId}
      >
        {children}
      </button>
    )
  }

  return (
    <Tooltip title={title} text={text} style="light-beige" onShow={onShow}>
      <span className="cursor-help" data-testid={dataTestId} tabIndex={0}>
        {children}
      </span>
    </Tooltip>
  )
}


export default Explanation
