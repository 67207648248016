
import { useQuery } from 'apollo-client'
import { getAnalyticsContext } from 'analytics'

import recentlyViewedQuery, { type RecentlyViewedVariables } from './graph/recentlyViewed.graphql'


type UseRecentlyViewedProps = {
  limit?: number
  placement: string
  productsFilter?: RecentlyViewedVariables['input']['productsFilter']
  skip?: boolean
}

export const useRecentlyViewed = ({ limit, placement, productsFilter, skip }: UseRecentlyViewedProps) => {
  const { page, pageType } = getAnalyticsContext()

  const { data, isFetching } = useQuery(recentlyViewedQuery, {
    variables: {
      input: {
        limit,
        metadata: {
          page,
          pageType,
          placement,
        },
        productsFilter,
      },
    },
    fetchPolicy: 'no-cache',
    skip,
    ssr: false,
  })

  return {
    data: data?.recommendationsRecentlyViewed?.data,
    isFetching,
  }
}
