import config from 'config'


const base64RegExp = /^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/


const sanitizeRedirectUrl = (rawRedirectUrl: string): string => {
  if (!rawRedirectUrl || typeof rawRedirectUrl !== 'string') {
    return null
  }

  let redirectUrl = rawRedirectUrl

  try {
    if (redirectUrl.match(base64RegExp)) {
      const decoded = Buffer.from(rawRedirectUrl, 'base64').toString('utf8')
      const reEncoded = Buffer.from(decoded).toString('base64')
      // check to make 100% sure that the list is really base64
      if (reEncoded === redirectUrl) {
        redirectUrl = decoded
      }
    }
  }
  catch (e) {
    // ignore error
  }

  try {
    // Try to parse the URL
    const origin = __CLIENT__ ? window.location.origin : config.publicUrl
    const host = new URL(origin).host

    const url = new URL(redirectUrl, origin)

    // If protocol is not http/https or host doesn't match current domain, reject it
    if (![ 'http:', 'https:' ].includes(url.protocol) || url.host !== host) {
      return null
    }

    // Remove any null bytes, control characters and Unicode whitespace
    let sanitized = url.pathname.replace(/[\x00-\x1F\x7F-\x9F\s]/g, '').trim()
      + url.search.replace(/[\x00-\x1F\x7F-\x9F\s]/g, '').trim()

    // remove start slashes
    sanitized = sanitized.replace(/^\/{2,}/, '')
    // Ensure path starts with /
    sanitized = sanitized.startsWith('/') ? sanitized : '/' + sanitized

    // if path contains % in a first part, reject it
    if (sanitized.match(/^\/[^\/?]*?%/)) {
      return null
    }

    return sanitized

  }
  catch (e) {
    // If URL parsing fails, return default path
    return null
  }
}

export default sanitizeRedirectUrl
