import React from 'react'
import links from 'links'
import { track } from 'analytics'
import { useUser } from 'modules/user'

import { Button } from 'components/inputs'

import messages from '../messages'


const TakeQuizButton = () => {
  const { user } = useUser()

  const hasScentProfile = Boolean(user?.analyticsMetadata?.hasScentProfile)

  return (
    <Button
      fullWidthOnMobile
      className="mt-24"
      rightIcon="32/arrow-right"
      size={56}
      width={320}
      title={hasScentProfile ? messages.retakeQuiz : messages.takeQuiz}
      to={links.smartRecommendations}
      onClick={() =>
        track('Banner click', {
          placement: 'Build your scent profile',
          content: 'Quiz',
          link: links.smartRecommendations,
          action: 'link',
        })}
      data-testid="takeQuiz"
    />
  )
}


export default TakeQuizButton
