import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { constants } from 'helpers'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import { useIntl } from 'intl'

import { WidthContainer } from 'components/layout'
import { Image, Text } from 'components/dataDisplay'

import GetStartedButton from '../GetStartedButton/GetStartedButton'

import bottlesGif from './images/bottles.gif?src-loader'
import bottlesStatic from './images/bottles-static.jpg'
import cartGif from './images/cart.gif?src-loader'
import cartStatic from './images/cart-static.jpg'
import caseGif from './images/case.gif?src-loader'
import caseStatic from './images/case-static.jpg'
import handshakeGif from './images/handshake.gif?src-loader'
import handshakeStatic from './images/handshake-static.jpg'

import messages from './messages'
import type { HomePageCustomBlockButton } from '../../View'


type HowItWorksSectionWithAnimationsProps = {
  className?: string
  startButtonMessage?: string | Intl.Message
  showStartButton?: boolean
  customButton?: HomePageCustomBlockButton
}

const HowItWorksSectionWithAnimations: React.FunctionComponent<HowItWorksSectionWithAnimationsProps> = (props) => {
  const { className, startButtonMessage, showStartButton = true, customButton } = props

  const isAiSummaryEnabled = useFeatureIsOn(constants.features.aiSummary)
  const isAiSummaryLandingHowFeatureEnabled = useFeatureIsOn(constants.features.aiSummaryLandingHow)
  // do not delete this line, it's required for AA tests
  const isHowItWorksEnabled = useFeatureIsOn(constants.features.aaHowItWorks)

  const { isMobile } = useDevice()

  const isAiSummaryLandingHowEnabled = isAiSummaryEnabled && isAiSummaryLandingHowFeatureEnabled

  return (
    <WidthContainer className={cx(className, 'flex flex-col items-center')}>
      <Text
        className={cx('text-center', isMobile ? 'mb-56' : 'mb-64')}
        style={isMobile ? 'h5' : 'h3'}
        message={messages.title}
        html
      />
      <div className={cx('flex', isMobile ? 'flex-col gap-40' : 'items-baseline gap-16')}>
        <FeatureItem
          animationImageSrc={bottlesGif}
          staticImageSrc={bottlesStatic}
          title={messages.features.bottles.title}
          subtitle={{
            ...(isAiSummaryLandingHowEnabled ? messages.features.bottles.subtitleAiSummary : messages.features.bottles.subtitle),
            values: {
              fragrancesAmount: constants.businessLogic.fragrancesAmount,
            },
          }}
        />
        <FeatureItem
          animationImageSrc={cartGif}
          staticImageSrc={cartStatic}
          title={messages.features.cart.title}
          subtitle={messages.features.cart.subtitle}
        />
        <FeatureItem
          animationImageSrc={caseGif}
          staticImageSrc={caseStatic}
          title={messages.features.case.title}
          subtitle={messages.features.case.subtitle}
        />
        <FeatureItem
          animationImageSrc={handshakeGif}
          staticImageSrc={handshakeStatic}
          title={messages.features.handshake.title}
          subtitle={messages.features.handshake.subtitle}
        />
      </div>
      {
        showStartButton && (
          <GetStartedButton
            className="mt-56"
            title={startButtonMessage}
            width={320}
            fullWidthOnMobile
            placement="How it works"
            {...customButton}
          />
        )
      }
    </WidthContainer>
  )
}

type FeatureItemProps = {
  className?: string
  animationImageSrc: Image.Src
  staticImageSrc: string
  title: string | Intl.Message
  subtitle: string | Intl.Message
}

const FeatureItem: React.FunctionComponent<FeatureItemProps> = (props) => {
  const { className, animationImageSrc, staticImageSrc, title, subtitle } = props

  const intl = useIntl()

  const { isMobile } = useDevice()

  return (
    <div className={cx(className, 'flex items-center', isMobile ? 'gap-32' : 'min-w-[273rem] flex-col gap-16 text-center')}>
      <picture>
        <source
          srcSet={staticImageSrc}
          media="(prefers-reduced-motion: reduce)"
          width={80}
          height={80}
        />
        <Image
          className="size-[80rem] flex-none"
          src={animationImageSrc}
          alt={intl.formatMessage(messages.imageAlt)}
          remWidth={80}
          lazy
          unoptimized
        />
      </picture>
      <div className="flex-1">
        <Text
          style="h6"
          message={title}
          html
        />
        <Text
          className="mt-8"
          style="p4"
          message={subtitle}
          html
        />
      </div>
    </div>
  )
}


export default React.memo(HowItWorksSectionWithAnimations)
