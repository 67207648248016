export default {
  initialSeo: {
    title: {
      en: 'Scentbird Monthly Perfume Subscription Box: Designer Scents {price}',
    },
    description: {
      en: `
      Scentbird's perfume subscription offers you to test-drive scents before committing to a full bottle.
      Get a designer fragrance each month for {price}.
    `,
    },
  },
  cleanPerfumes: {
    title: {
      en: 'Clean fragrances',
    },
  },
  newArrivalsPerfumes: {
    title: {
      en: 'New in Fine Fragrances',
    },
    description: {
      en: 'The newest perfumes and colognes to land at the Scentbird nest.',
    },
  },
  giftSetsPerfumes: {
    title: {
      en: 'Scentbird Gift sets',
    },
    description: {
      en: `Spoil 'em with ridiculously amazing scents`,
    },
  },
  giftSetsColognes: {
    title: {
      en: 'Scentbird Gift sets',
    },
    description: {
      en: `Spoil 'em with ridiculously amazing scents`,
    },
  },
  giftSetsUnisex: {
    title: {
      en: 'Scentbird Gift sets',
    },
    description: {
      en: `Spoil 'em with ridiculously amazing scents`,
    },
  },
  terms: {
    title: {
      en: 'Terms & Conditions',
    },
  },
  privacy: {
    title: {
      en: 'Privacy policy',
    },
  },
  press: {
    title: {
      en: 'Press resources',
    },
  },
  team: {
    title: {
      en: 'Team of Perfume Subscription Service',
    },
  },
  cardUpdate: {
    title: {
      en: 'Card update',
    },
  },
  shopSamplePerfumes: {
    title: {
      en: 'Scentbird Shop Samples',
    },
    description: {
      en: `Spoil 'em with ridiculously amazing scents`,
    },
  },
  shopSampleColognes: {
    title: {
      en: 'Scentbird Shop Samples',
    },
    description: {
      en: `Spoil 'em with ridiculously amazing scents`,
    },
  },
  giftSubscription: {
    title: {
      en: 'The Perfect Gift: Perfume Subscription Starting at {giftSubscriptionPrice}',
    },
    description: {
      en: `
      Shop perfume gift at Scentbird.com.
      View our unrivaled assortment from top brands and find a luxurious perfume gift set for every woman in your life.
    `,
    },
  },
  bestsellersPerfumes: {
    title: {
      en: 'Best Sellers',
    },
    description: {
      en: 'The Scentbird community has spoken, and these are the most-popular fragrance picks.',
    },
  },
  subscriptionPerfumeOfTheMonthList: {
    title: {
      en: 'Fragrance of the month archive',
    },
  },
  collectionsWomen: {
    title: {
      en: 'Collections',
    },
  },
}
