import React, { useState, useEffect } from 'react'
import { cx } from 'helpers'
import { useDevice } from 'device'

import { Image } from 'components/dataDisplay'


type FadeImageProps = {
  src: string
  alt?: string
}

const TRANSITION_DURATION = 500

const FadeImage: React.FC<FadeImageProps> = ({ src, alt = '' }) => {
  const { isMobile } = useDevice()

  const [ currentSrc, setCurrentSrc ] = useState(src)
  const [ isVisible, setIsVisible ] = useState(false)

  const handleLoad = () => {
    requestAnimationFrame(() => {
      setIsVisible(true)
    })
  }

  useEffect(() => {
    if (src !== currentSrc) {
      setIsVisible(false)

      const timeout = setTimeout(() => {
        setCurrentSrc(src)
      }, TRANSITION_DURATION)

      return () => clearTimeout(timeout)
    }
  }, [ src, currentSrc ])

  return (
    <Image
      alt={alt}
      src={currentSrc}
      remWidth={isMobile ? 76 : 324}
      onLoad={handleLoad}
      className={cx(
        'ease aspect-square object-contain transition-opacity duration-500 will-change-[opacity]',
        isMobile ? 'size-[76rem]' : 'size-[324rem]',
        isVisible ? 'opacity-100' : 'opacity-0'
      )}
    />
  )
}


export default FadeImage
