export default {
  defaultTitle: {
    en: 'It’s the holiday season!',
  },
  valentinesGGFocused: {
    en: '<span class="text-gold-30">SCENT WITH LOVE:</span> shop the<br class="onlyMobile"/> v-day gift guide',
  },
  valentinesUpTo60Off: {
    en: '<span class="text-gold-30">Hurry, love!</span> Our up to 60% off<br class="onlyMobile"/> sale ends February 14th',
  },
  valentinesGiftSubPush: {
    en: 'Give the gift of instant joy<br class="onlyMobile"/> with a gift subscription',
  },
  title: {
    en: '{discount}% off select products',
  },
  button: {
    en: 'Shop now',
  },
}
