import { useCallback } from 'react'
import { useApolloClient } from 'apollo-client'
import { useGrowthBook } from '@growthbook/growthbook-react'
import { constants, GraphQLError } from 'helpers'
import { mixpanel, rudderstack } from 'analytics'
import localStorage from 'local-storage'
import cookieStorage from 'cookie-storage'

import userLogoutQuery from './graph/userLogout.graphql'


const cookiesToDelete: string[] = [
  constants.cookieNames.discountCoupon,
  constants.cookieNames.country,
  constants.cookieNames.appliedCouponCode,
  constants.cookieNames.isFreeTrialCoupon,
  constants.cookieNames.resubscribeOffer,
  constants.cookieNames.selectedPlan,
  constants.cookieNames.isAddToQueueCaseUpsellModalShown,
  constants.cookieNames.isAddToCartCaseUpsellModalShown,
  constants.cookieNames.isHereticCaseUpsellModalShown,
  constants.cookieNames.isStrawberryShortcakeCaseUpsellModalShown,
  constants.cookieNames.cardUpdateOffer,
  constants.cookieNames.growthbookUid,
]

const localStorageToDelete: string[] = [
  constants.localStorageNames.shippingFormValues,
  constants.localStorageNames.authGender,
  constants.localStorageNames.authEmail,
  constants.localStorageNames.queuePendingProducts,
  constants.localStorageNames.queueHighPriorityProducts,
  constants.localStorageNames.queueLowPriorityProducts,
  constants.localStorageNames.iterableOptInPendingProjects,
]

const sessionStorageToDelete: string[] = [
  constants.localStorageNames.trackedExperiments,
]

export const useLogout = () => {
  const client = useApolloClient()
  const gb = useGrowthBook()

  return useCallback(async ({ withLocalDataReset = true } = {}) => {
    const result = await client.mutate({
      mutation: userLogoutQuery,
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    if (withLocalDataReset) {
      // ATTN we don't care about an error
      mixpanel.reset()
      rudderstack.reset()
      await gb.setAttributes({})
      localStorageToDelete.forEach((name) => localStorage.removeItem(name))
      cookiesToDelete.forEach((name) => cookieStorage.removeItem(name))
      sessionStorageToDelete.forEach((name) => localStorage.removeSessionItem(name))
    }
  }, [ client, gb ])
}
