import { useMemo } from 'react'
import { useFeatureIsOn } from '@growthbook/growthbook-react'
import links, { getLinkWithParams } from 'links'

import { constants } from 'helpers'
import { useFt } from 'hooks'

import { useStrapiWSTONavigationData } from 'modules/brands'
import { useLimitedDropNavigationItems } from 'modules/limitedDrops'
import { messages as globalMessages } from 'modules/messages'
import { usePrivateSale } from 'modules/products'
import { useCandleSubscription, useCaseSubscription, useDriftSubscription, useSamplesSubscription, useSubscription } from 'modules/subscription'
import { useReferralInvites, useUser } from 'modules/user'

import { navMessages as messages } from 'compositions/navigation'


const useNav = () => {
  const isGiftHubEnabled = useFt(constants.features.giftHub)
  const isTheShopEnabled = useFt(constants.features.theShop)
  const isCandleSubscriptionEnabled = useFt(constants.features.candleSubscription)
  const isFragranceFamiliesPageEnabled = useFt(constants.features.fragranceFamiliesPage)
  const isNotesLibraryPageEnabled = useFt(constants.features.noteLibPages)
  const isSaksCollaborationEnabled = useFt(constants.features.collaborationSaks)
  const isScentHoroscopeEnabled = useFt(constants.features.scentHoroscope)
  const isScentbirdPodcastEntryPointsEnabled = useFt(constants.features.scentbirdPodcastEntryPoints)
  const isDriftEcommercePromoEnabled = useFt(constants.features.driftEcommercePromo)
  const isCOARShopPageEnabled = useFt(constants.features.coarShopPage)
  const isStrapiCareersPageEnabled = useFeatureIsOn(constants.features.strapiCareersPage)
  const isHolidayMothersDayEnabled = useFt(constants.features.holidayMothersDay)
  const isGiftHubEvergreenEnabled = useFeatureIsOn(constants.features.giftHubEvergreen)
  const isTheSummerEditEnabled = useFeatureIsOn(constants.features.theSummerEdit)
  const isTheEditHolidayEnabled = useFeatureIsOn(constants.features.theEditHoliday)
  const isTheEditValentinesDayEnabled = useFeatureIsOn(constants.features.theEditValentinesDay)
  const isSamplesSubscriptionEnabled = useFeatureIsOn(constants.features.addonSamples)
  const isBlackFridaySaleEnabled = useFeatureIsOn(constants.features.blackFridaySale)

  const { gender, hasScentProfile } = useUser()

  const strapiWSTONavigationData = useStrapiWSTONavigationData()
  const isStrapiWSTONavigationEnabled = Boolean(strapiWSTONavigationData)


  const { privateSale } = usePrivateSale()
  const isPrivateSaleEnabled = Boolean(privateSale)

  const { subscription } = useSubscription()

  const isLead = subscription?.hasNeverSubscribed
  const isSubscribed = subscription?.isSubscribed
  const isBuyQueueEnabled = subscription?.stateOptions?.canBuyQueue
  const canApplyTossInOffer = subscription?.canApplyTossInOffer

  const { caseSubscription } = useCaseSubscription()
  const isCaseSubscriptionEnabled = caseSubscription?.isEnabled

  const { driftSubscription } = useDriftSubscription()
  const isDriftSubscriptionEnabled = driftSubscription?.isEnabled

  const { candleSubscription } = useCandleSubscription()
  const isCandleSubscriptionVisible = Boolean(isCandleSubscriptionEnabled && candleSubscription?.isEnabled)

  const { samplesSubscription } = useSamplesSubscription({ skip: !isSamplesSubscriptionEnabled })
  const isSamplesSubscriptionVisible = Boolean(isSamplesSubscriptionEnabled && samplesSubscription?.isEnabled)

  const { stats: { available } } = useReferralInvites()

  const limitedDropNavigationItems = useLimitedDropNavigationItems({ placement: 'footer' })

  return useMemo(() => {
    let theEditTitle = messages.edit

    if (isHolidayMothersDayEnabled) {
      theEditTitle = globalMessages.theMothersDayEdit
    }
    else if (isTheSummerEditEnabled) {
      theEditTitle = messages.theSummerEdit
    }
    else if (isTheEditHolidayEnabled) {
      theEditTitle = messages.theEditHoliday
    }
    else if (isTheEditValentinesDayEnabled) {
      theEditTitle = messages.theEditValentinesDay
    }

    const giftHubNavigationTitle = isGiftHubEnabled
      ? isGiftHubEvergreenEnabled ? messages.giftsEvergreen : messages.gifts
      : null

    const data: Navigation.FooterSection[] = [
      {
        id: 'footer-mySubscription',
        title: isSubscribed ? messages.mySubscription : messages.scentbird,
        list: [
          {
            id: 'footer-mySubscription-perfumes',
            title: messages.perfumes,
            to: links.subscription.perfumes,
          },
          {
            id: 'footer-mySubscription-colognes',
            title: messages.colognes,
            to: links.subscription.colognes,
          },
          {
            id: 'footer-mySubscription-beauty',
            title: messages.beauty,
            to: links.subscription.beauty,
          },
          isLead && ({
            id: 'footer-perfume-subscription',
            title: messages.perfumeSubscription,
            href: constants.externalLinks.scentbirdPerfumeBlog,
          }),
          isLead && ({
            id: 'footer-cologne-subscription',
            title: messages.cologneSubscription,
            href: constants.externalLinks.scentbirdCologneBlog,
          }),
        ].filter(Boolean),
        withColumns: false,
      },
      {
        id: 'footer-shop',
        title: messages.shop,
        list: [
          ...(limitedDropNavigationItems.Shop || []),
          isDriftEcommercePromoEnabled && {
            id: 'footer-shop-drift-ecommerce-promo',
            title: messages.driftEcommercePromo,
            to: `${getLinkWithParams(links.brand, { name: 'drift' })}?productInputSource=THE_EDIT&sourceProductGroup=GiftSet`,
          },
          (isCOARShopPageEnabled && !isBlackFridaySaleEnabled) && {
            id: 'header-shop-coar',
            title: messages.coarShopPage,
            to: links.shop.coar,
          },
          isTheShopEnabled && {
            id: 'footer-shop-edit',
            title: theEditTitle,
            to: links.shop.theEdit,
          },
          {
            id: 'footer-shop-scentbirdCases',
            title: messages.scentbirdCases,
            to: links.shop.scentbirdCases,
          },
          isTheShopEnabled && {
            id: 'footer-shop-fullSizeFragrances',
            title: messages.fullSizeFragrances,
            to: gender === 'FEMALE' ? links.shop.perfumes : links.shop.colognes,
          },
          isTheShopEnabled && {
            id: 'footer-shop-bathBody',
            title: messages.bathBody,
            to: links.shop.bathBody,
          },
          isTheShopEnabled && {
            id: 'footer-shop-candlesHomeScents',
            title: messages.candlesHomeScents,
            to: links.shop.candlesHomeScents,
          },
          isGiftHubEnabled && {
            id: 'footer-shop-gifts',
            title: giftHubNavigationTitle,
            to: links.gifts,
          },
        ].filter(Boolean),
        withColumns: true,
      },
      {
        id: 'footer-discover',
        title: messages.discover,
        list: [
          ...(limitedDropNavigationItems.Discover || []),
          {
            id: 'footer-discover-scentProfile',
            title: messages.scentProfile,
            to: hasScentProfile ? links.scentProfile : links.smartRecommendations,
          },
          isSaksCollaborationEnabled && ({
            id: 'footer-discover-scentbird-select',
            title: messages.scentbirdSelect,
            to: links.scentbirdSelect,
          }),
          {
            id: 'footer-discover-allBrands',
            title: messages.allBrands,
            to: links.brands,
          },
          isScentHoroscopeEnabled && {
            id: 'footer-discover-scentHoroscope',
            title: messages.scentHoroscope,
            to: links.scentHoroscope,
          },
          isStrapiWSTONavigationEnabled && {
            id: 'footer-discover-featuredBrand',
            title: messages.featuredBrand,
            to: strapiWSTONavigationData.navLink,
          },
          isNotesLibraryPageEnabled && {
            id: 'footer-discover-notesLibrary',
            title: messages.notesLibrary,
            to: links.notes,
          },
          isFragranceFamiliesPageEnabled && {
            id: 'footer-discover-fragranceFamilies',
            title: messages.fragranceFamilies,
            to: links.fragranceFamilies,
          },
          {
            id: 'footer-discover-scentBlog',
            title: messages.scentBlog,
            toTab: links.blog,
          },
          isScentbirdPodcastEntryPointsEnabled && {
            id: 'footer-discover-podcast',
            title: messages.scentWorldPodcast,
            href: constants.externalLinks.scentbirdPodcast,
          },
        ].filter(Boolean),
        withColumns: true,
      },
      isSubscribed && {
        id: 'footer-member-perks',
        title: messages.memberPerks,
        list: [
          isSamplesSubscriptionVisible && {
            id: 'footer-member-perks-samplesSubscription',
            title: messages.samplesSubscription,
            to: links.samplesSubscription,
          },
          isCaseSubscriptionEnabled && {
            id: 'footer-member-perks-caseSubscription',
            title: messages.caseSubscription,
            to: links.caseSubscription,
          },
          isDriftSubscriptionEnabled && {
            id: 'footer-member-perks-driftSubscription',
            title: messages.driftSubscription,
            to: links.driftSubscription,
          },
          isCandleSubscriptionVisible && {
            id: 'footer-member-perks-candleSubscription',
            title: messages.candleSubscription,
            to: links.candleSubscription,
          },
          isBuyQueueEnabled && {
            id: 'footer-member-perks-buyYourQueue',
            title: messages.buyYourQueue,
            to: links.buyQueue,
          },
          isPrivateSaleEnabled && {
            id: 'footer-member-perks-privateSale',
            title: messages.privateSale,
            to: links.privateSale,
          },
          canApplyTossInOffer && {
            id: 'footer-member-perks-lastMinuteAddons',
            title: messages.lastMinuteAddons,
            to: links.tossIn,
          },
          Boolean(available) && {
            id: 'footer-member-perks-referralProgram',
            title: {
              ...messages.newReferral,
              values: {
                available,
                freeFragranceClassName: 'text-gold-30',
                spanClassName: 'bg-gold-50 text-white',
              },
            },
            to: links.invite,
          },
        ].filter(Boolean),
        withColumns: true,
      },
      {
        id: 'footer-about-scentbird',
        title: messages.aboutScentbird,
        list: [
          {
            id: 'footer-about-scentbird-support',
            title: messages.support,
            toTab: 'https://scentbird.zendesk.com/hc/en-us',
          },
          {
            id: 'footer-about-scentbird-aboutUs',
            title: messages.aboutUs,
            to: links.team,
          },
          {
            id: 'footer-about-scentbird-careers',
            title: messages.careers,
            toTab: isStrapiCareersPageEnabled ? links.careers : constants.externalLinks.ripplingJobs,
          },
          {
            id: 'footer-about-scentbird-press',
            title: messages.press,
            to: links.press,
          },
        ],
        withColumns: true,
      },
    ]

    return data.filter(Boolean)
  }, [
    canApplyTossInOffer,
    gender,
    hasScentProfile,
    strapiWSTONavigationData,
    isStrapiWSTONavigationEnabled,
    isBuyQueueEnabled,
    isCandleSubscriptionVisible,
    isCaseSubscriptionEnabled,
    isPrivateSaleEnabled,
    isFragranceFamiliesPageEnabled,
    isNotesLibraryPageEnabled,
    isLead,
    isSubscribed,
    isTheShopEnabled,
    isSaksCollaborationEnabled,
    isScentHoroscopeEnabled,
    isScentbirdPodcastEntryPointsEnabled,
    isDriftEcommercePromoEnabled,
    available,
    isCOARShopPageEnabled,
    isDriftSubscriptionEnabled,
    isStrapiCareersPageEnabled,
    isGiftHubEvergreenEnabled,
    isGiftHubEnabled,
    isTheSummerEditEnabled,
    isTheEditHolidayEnabled,
    isTheEditValentinesDayEnabled,
    isHolidayMothersDayEnabled,
    isSamplesSubscriptionVisible,
    isBlackFridaySaleEnabled,
    limitedDropNavigationItems,
  ])
}


export default useNav
