import { useCallback, useMemo, useRef } from 'react'
import logger from 'logger'
import { useApolloClient, useMutation, useQuery, type ApolloCache } from 'apollo-client'
import localStorage from 'local-storage'
import { CancelledPromiseError, constants, GraphQLError, UserError } from 'helpers'
import { checkUpsellModalCookie } from 'helpers/getters'
import { openModal } from 'modals'
import { openNotification } from 'notifications'
import { useHistory } from 'router'
import links from 'links'
import { track } from 'analytics'
import { useAb, useFt } from 'hooks'
import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useSubscription } from 'modules/subscription'
import { useShownPopups, useUser } from 'modules/user'
import { isOptInModalShouldBeShown } from 'modules/user/optIn'
import type { QueueAddItemInput } from 'typings/graphql'


import modifyQueueItems from './modifyQueueItems'
import filterEmptyQueueItems from './filterEmptyQueueItems'

import type { QueueVariables, QueuePayload } from './graph/queue.graphql'
import queueQuery from './graph/queue.graphql'

import queueAddMultipleItemsQuery from './graph/queueAddMultipleItems.graphql'
import queueMoveItemQuery from './graph/queueMoveItem.graphql'
import queueDeleteItemQuery from './graph/queueDeleteItem.graphql'
import queueUpdateItemQuery from './graph/queueUpdateItem.graphql'


export const getClosestAvailableMonth = (items: QueueModule.Item[]) => {
  return items.find(({ stateOptions }) => {
    return !stateOptions.isDisabled
  })
}

/**
 * serverIndex is unique ACTIVE product key
 * */

export const getProductFromQueueByFlatIndex = (serverIndex: number, items: QueueModule.Item[]) => {
  let item: QueueModule.Product

  items.some(({ products }) => {
    item = products.find(({ flatIndex }) => serverIndex === flatIndex)

    return item
  })

  return item
}

const getLastAddedProductByUid = (tradingItemUid: string, items: QueueModule.Item[], position: 'FIRST' | 'LAST'): any => {
  const itemsQuantity = items.length

  for (let i = 0; i < itemsQuantity; i++) {
    const itemIndex = position === 'FIRST' ? i : itemsQuantity - i - 1
    const productsQuantity = items[itemIndex].products.length

    for (let j = 0; j < productsQuantity; j++) {
      const productIndex = position === 'FIRST' ? j : productsQuantity - j - 1
      const product = items[itemIndex].products[productIndex]

      if (tradingItemUid === product?.tradingItem?.uid) {
        return product
      }
    }
  }

  return null
}

// TODO temp fix for backend hard limit (24 months) in queue
//  for users with higher fullness of queue - added on 02.08.2022 by sonatskiy
const useQueueMinimalLimitRef = () => {
  const { user } = useUser()

  const productsInQueueQty = user?.analyticsMetadata?.productsInQueueQty || 0

  const queueMinimalLimitRef = useRef<number>(null)
  queueMinimalLimitRef.current = Math.max(24, productsInQueueQty + 1)

  return queueMinimalLimitRef
}

export const useQueue = (input: QueueVariables['input'] = {}) => {
  const queueMinimalLimitRef = useQueueMinimalLimitRef()

  const { data, isFetching, error, refetch } = useQuery(queueQuery, {
    variables: {
      input: {
        limit: queueMinimalLimitRef.current,
        ...input,
      },
    },
    ssr: false, // client request only to improve ssr response
  })

  const { items, isEmpty, queueInfo } = useMemo(() => {
    const queueItems = data?.currentUser?.data?.queue?.queueItems

    const items = queueItems ? modifyQueueItems(filterEmptyQueueItems(queueItems)) : null
    const isEmpty = items?.[0]?.statuses.every((status) => status === 'EMPTY') || false

    /**
     * Checks queue items for two types of products:
     * 1. Premium products (products with upcharge price > 0, excluding Scentbird Select)
     * 2. Scentbird Select products (special premium category)
     *
     * Since Scentbird Select is a premium product by default (has upcharge price),
     * we need to check it first to avoid counting it twice. This way we can
     * track these categories separately in analytics.
     */
    const queueInfo = items?.reduce((acc, queueItem) => {
      if (acc.hasPremiumProduct && acc.hasScentbirdSelect) {
        return acc
      }

      queueItem.products.some((product) => {
        const productInfo = product?.tradingItem?.productInfo

        if (!productInfo) {
          return false
        }

        if (!acc.hasScentbirdSelect && productInfo.saksSelected) {
          acc.hasScentbirdSelect = true
        }
        else if (!acc.hasPremiumProduct && !productInfo.saksSelected && productInfo.upchargePrice) {
          acc.hasPremiumProduct = true
        }

        return acc.hasPremiumProduct && acc.hasScentbirdSelect
      })

      return acc
    }, { hasPremiumProduct: false, hasScentbirdSelect: false })

    return {
      items,
      isEmpty,
      queueInfo,
    }
  }, [ data ])

  return {
    items,
    isEmpty,
    isFetching,
    refetch,
    error,
    queueInfo,
  }
}


export const getPostponedItems = () => localStorage.getItem<QueueModule.AddItemsInput>(constants.localStorageNames.queuePendingProducts) || []

export const addItemsToPostponed = (items: QueueModule.AddItemsInput, position: 'FIRST' | 'LAST' = 'LAST') => {
  let postponedItems = localStorage.getItem<QueueModule.AddItemsInput>(constants.localStorageNames.queuePendingProducts) || []
  const existingUids = postponedItems.map(({ product }) => product.tradingItemUid)

  items.forEach((item) => {
    const { product } = item

    if (existingUids.includes(product.tradingItemUid)) {
      // if user want to add a product to the beginning of the queue and product already in the queue,
      // we should move the product to the first place
      if (position === 'FIRST') {
        postponedItems = postponedItems.filter((existingItem) => existingItem.product.tradingItemUid !== product.tradingItemUid)
        postponedItems.unshift(item)
      }
      return
    }

    if (position === 'FIRST') {
      postponedItems.unshift(item)
    }
    else {
      postponedItems.push(item)
    }
  })

  localStorage.setItem<QueueModule.AddItemsInput>(constants.localStorageNames.queuePendingProducts, postponedItems)
}

const getProductsInQueueQnt = (data: QueuePayload['currentUser']['data']['queue']) => {
  if (!data) {
    return null
  }

  return data.queueItems.reduce((result, item) => {
    return result + item.products?.length || 0
  }, 0)
}

const openAddedProductNotification = (items: QueueModule.AddItemsInput, count?: number) => {
  let productProps
  const isOnlyProduct = items.length === 1

  if (isOnlyProduct) {
    const { name, brand, image, rebrandImage } = items[0].product

    productProps = {
      name,
      brand,
      image,
      rebrandImage,
    }
  }

  openNotification('productAdded', {
    to: 'queue',
    count,
    ...productProps,
  })
}

const updateQueueCache = (cache: ApolloCache<any>, queueInput: QueueVariables['input'], queue: QueuePayload['currentUser']['data']['queue']) => {
  if (!queue) {
    return
  }

  // Clear all existing queue caches to refetch them
  cache.evict({ id: 'UserData:{}', fieldName: 'queue' })

  cache.writeQuery({
    query: queueQuery,
    variables: {
      input: queueInput,
    },
    data: {
      currentUser: {
        data: {
          queue: queue,
          __typename: 'UserData',
        },
        __typename: 'UserPayload',
      },
    },
  })
}

export const getQueueProducts = (queueItems: QueueModule.Items) => {
  return queueItems?.flatMap((item) => {
    let products = []

    if (item && !item.stateOptions.isEmpty) {
      products = item.products?.filter((product) => !product.stateOptions.isEmpty)
        .map((product) => {
          const { image, rebrandImage, id, upchargePrice } = product?.tradingItem?.productInfo ?? {}

          return {
            id,
            image: rebrandImage || image,
            month: item.month - 1,
            year: item.year,
            flatIndex: product.flatIndex,
            isMovable: product.stateOptions.isMovable,
            upchargePrice,
          }
        })
    }

    return products
  }) || []
}
// real adding to the queue with additional logic
const useQueueAddItemsBase = () => {
  const [ mutate, { isFetching, client } ] = useMutation(queueAddMultipleItemsQuery, {
    fetchPolicy: 'no-cache', // we update cache manually
  })

  const { shownPopups } = useShownPopups()
  const { subscription } = useSubscription()
  const { user, isLoggedIn } = useUser()

  const isOptInEnabled = useFt(constants.features.optInPopups)
  const isCaseUpsellEnabled = useFt(constants.features.caseUpsellAddToQueue)
  const isHereticCaseUpsellEnabled = useFt(constants.features.caseUpsellHeretic)
  const isStrawberryShortcakeCaseUpsellEnabled = useFeatureIsOn(constants.features.caseUpsellStrawberryShortcake)
  const isHidePopupsAbEnabled = useAb(constants.abTests.popupHidePopups) === 'b'
  const isPopupAlreadyTriedThisEnabled = useAb(constants.abTests.popupAlreadyTriedThis) === 'b'
  const isPopupSubscriptionsRedesign = useAb(constants.abTests.popupSubscriptionsRedesign) === 'b'

  const isLead = !isLoggedIn || user?.analyticsMetadata?.subscriptionStatus === 'LEAD'

  const queueMinimalLimitRef = useQueueMinimalLimitRef()

  // to keep useCallback unchanged
  const shownPopupsRef = useRef(shownPopups)
  shownPopupsRef.current = shownPopups

  const isUpgradableFromMonthlyPlanRef = useRef<boolean>(null)
  isUpgradableFromMonthlyPlanRef.current = subscription?.isUpgradableFromMonthlyPlan

  const isSubscriptionAvailableForPEMRef = useRef<boolean>(null)
  isSubscriptionAvailableForPEMRef.current = (
    (subscription?.isActive || subscription?.isPending)
    && subscription?.plan?.shippingPeriod === 1
  )

  const isAddPremiumModalAvailableRef = useRef<boolean>(null)
  isAddPremiumModalAvailableRef.current = !shownPopups?.['addPremiumModal']?.lastUpdated

  const isCaseSubscriptionUpsellModalAvailableRef = useRef<boolean>(null)
  isCaseSubscriptionUpsellModalAvailableRef.current = (
    subscription?.isActive
    && subscription?.isCaseSubscriptionAvailable
  )

  const action = useCallback<QueueModule.AddItemsBase>(async (items, params = {}) => {
    const {
      withNotification = true, withNotificationOnly = false, withNonAuthRedirect = true, withUpgradeFlow = true,
      withErrorModals = true, withoutPremiumModal, withCaseUpsellModal = false,
    } = params

    const isOnlyProduct = items.length === 1

    const queueItemAddInputList: QueueAddItemInput[] = items.map(({ product, force = false, metadata, position = 'LAST' }) => ({
      tradingItemUid: product.tradingItemUid,
      force,
      metadata,
      position,
    }))

    const isPlacementInvalid = queueItemAddInputList.some(({ metadata }) => !metadata?.placement || /^[a-z]/.test(metadata.placement))

    if (isPlacementInvalid) {
      logger.error({ items }, 'add to queue invalid placement')
    }

    const queueInput = {
      limit: queueMinimalLimitRef.current + queueItemAddInputList.length,
    }

    const result = await mutate({
      variables: {
        mutationInput: {
          queueItemAddInputList,
        },
        queueInput: queueInput,
      },
      update: (cache, result) => {
        updateQueueCache(cache, queueInput, result.data.queueAddMultipleItems.data?.queue)
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.queueAddMultipleItems

    const resultQueue = data?.queue
    const productsInQueueQty = resultQueue ? getProductsInQueueQnt(resultQueue) : null

    if (productsInQueueQty !== null) {
      client.cache.modify({
        id: 'PersonalInfo:{}',
        fields: {
          analyticsMetadata: ((value) => ({
            ...value,
            productsInQueueQty,
          })),
        },
      })
    }

    if (error) {
      if (error.__typename === 'SecurityError' && error.securityErrorCode === 'NOT_AUTHENTICATED') {
        addItemsToPostponed(items)

        if (withNonAuthRedirect) {
          throw new UserError(error)
        }

        throw new CancelledPromiseError(error.message)
      }

      if (withErrorModals) {
        if (error.__typename === 'QueueAddMultipleItemsError') {
          const itemError = error.errorDetails[0]
          const item = items.find(({ product: { tradingItemUid } }) => tradingItemUid === itemError.tradingItemUid)
          const { productUid, isSubscribedToStockNotification } = item.product

          // TODO add queue error handling - added on 19.01.2022 by sonatskiy
          const errorCode = itemError.businessErrorCode || itemError.productValidationErrorCode

          if (errorCode === 'UPCHARGE_NEED_PAYMENT_DATA') {
            openModal('needPaymentDataModal', { items })
            throw new CancelledPromiseError()
          }
          else if (
            errorCode === 'NO_SUBSCRIPTION'
            || errorCode === 'NO_ACTIVE_SUBSCRIPTION'
          ) {
            openModal('activateAccountModal', { item })
            throw new CancelledPromiseError()
          }
          else if (errorCode === 'RESTRICT_SAME_PERFUME') {
            // TODO: Refactor error handling and add analytics — added on 02–04–2022 by algeas
            if (isPopupAlreadyTriedThisEnabled) {
              openModal('sameProductAbModal', { item })
            }
            else {
              openModal('sameProductModal', { item })
            }

            throw new CancelledPromiseError()
          }
          else if (errorCode === 'LEP_OUT_OF_STOCK') {
            openModal('outOfStockModal', {
              productUid,
              isSubscribedToStockNotification,
            })
            throw new CancelledPromiseError()
          }
          else if (errorCode === 'SUBSCRIPTION_ON_HOLD') {
            openModal('cancelSkipModal', {
              item,
            })
            throw new CancelledPromiseError()
          }

          const customMessage = itemError.message

          throw new UserError(
            {
              ...itemError,
              errorCode,
            },
            customMessage
          )
        }

        throw new UserError(error)
      }

      // otherwise, ignore errors and resolve promise
      return
    }

    if (withNotificationOnly) {
      openAddedProductNotification(items, data?.productsAdded)

      return
    }

    if (withNotification) {
      if (isOnlyProduct) {
        const queueItems = modifyQueueItems(filterEmptyQueueItems(resultQueue?.queueItems))

        const { position } = queueItemAddInputList[0]
        const { tradingItemUid, isLimitedEdition, upchargePrice } = items[0].product

        const closestMonthProducts = getClosestAvailableMonth(queueItems)?.products
        const lastAddedProduct = getLastAddedProductByUid(tradingItemUid, queueItems, position)

        if (!lastAddedProduct) {
          logger.error({ tradingItemUid }, 'Last added product not found in the queue')
        }

        const isMovedToClosestMonth = closestMonthProducts.some(({ flatIndex }) => {
          return flatIndex === lastAddedProduct?.flatIndex
        })

        const isLEPPutFirstInQueueModalVisible = (
          isLimitedEdition
          && !isMovedToClosestMonth
          && isSubscriptionAvailableForPEMRef.current
          && Boolean(lastAddedProduct)
        )

        const LEPPutFirstModalProps = isLEPPutFirstInQueueModalVisible ? {
          productIndex: lastAddedProduct?.flatIndex,
          onClose: () => {
            if (withNotification) {
              openAddedProductNotification(items, data?.productsAdded)
            }
          },
        } : null

        const brandSlug = lastAddedProduct?.tradingItem?.productInfo.brandInfo.slug
        const isOptInModalVisible = (
          isOptInEnabled
          && isOptInModalShouldBeShown({ brandSlug, shownPopups: shownPopupsRef.current })
          && !isLead
        )

        if (isOptInModalVisible) {
          openModal('brandOptInModal', {
            priority: 1,
            brandSlug,
            onClose: () => {
              if (withNotification) {
                openAddedProductNotification(items, data?.productsAdded)
              }
            },
          })

          return
        }

        if (lastAddedProduct && !withoutPremiumModal && upchargePrice && isAddPremiumModalAvailableRef.current) {
          openModal('addPremiumModal', {
            product: lastAddedProduct,
            onClose: () => {
              if (isLEPPutFirstInQueueModalVisible) {
                openModal('putFirstToQueueModal', LEPPutFirstModalProps)
              }
              else {
                if (withNotification) {
                  openAddedProductNotification(items, data?.productsAdded)
                }
              }
            },
          })

          return
        }

        if (isLEPPutFirstInQueueModalVisible) {
          openModal('putFirstToQueueModal', LEPPutFirstModalProps)

          return
        }
      }
      else {
        // TODO: The logic of adding several products requires product development — added on 02–04–2022 by algeas
      }
    }

    if (withUpgradeFlow && isUpgradableFromMonthlyPlanRef.current) {
      // 36 added because of the BE issue, when we return 36 queue products max
      // Users with big queues see this popup every addToQueue action, which is bad
      // TODO remove 36 after BE fix - added on 2024-05-17 by maddoger
      if (productsInQueueQty % 6 === 0 && productsInQueueQty !== 36) {
        openModal('addToQueueUpgradeModal')

        if (withNotification) {
          openAddedProductNotification(items, data?.productsAdded)
        }

        return
      }
    }

    // code duplication to show this popup after upgrade
    if (withNotification && isOnlyProduct && withCaseUpsellModal) {
      const { product } = items[0]
      let isRedesignedCaseSubscriptionUpsellModalVisible = false

      if (product.isTravelSizeVial) {
        const caseUpsellConfig = [
          {
            brand: 'HERETIC',
            isEnabled: isHereticCaseUpsellEnabled,
            modalName: 'hereticCaseUpsellModal' as const,
            cookieName: constants.cookieNames.isHereticCaseUpsellModalShown,
          },
          {
            brand: 'STRAWBERRY SHORTCAKE',
            isEnabled: isStrawberryShortcakeCaseUpsellEnabled,
            modalName: 'strawberryShortcakeCaseUpsellModal' as const,
            cookieName: constants.cookieNames.isStrawberryShortcakeCaseUpsellModalShown,
          },
        ].find((item) => {
          return item.isEnabled && product.brand.toUpperCase().startsWith(item.brand)
        })

        if (caseUpsellConfig) {
          const cookieCondition = checkUpsellModalCookie({
            cookieName: caseUpsellConfig.cookieName,
            countPerDay: 5,
            isSetCookieOnClose: !isHidePopupsAbEnabled,
          })

          const isVisible = (
            cookieCondition.isVisible
            && !isLead
          )

          if (isVisible) {
            openModal(caseUpsellConfig.modalName, {
              product,
              addTo: 'queue',
              priority: 4,
              onClose: cookieCondition.onClose,
              onShow: cookieCondition.onShow,
            })

            return
          }
        }
        else if (isCaseUpsellEnabled && isCaseSubscriptionUpsellModalAvailableRef.current) {
          const { isVisible, onShow, onClose } = checkUpsellModalCookie({
            cookieName: constants.cookieNames.isAddToQueueCaseUpsellModalShown,
            countPerDay: 2,
            isSetCookieOnClose: !isHidePopupsAbEnabled,
          })

          if (isVisible) {
            if (isPopupSubscriptionsRedesign) {
              isRedesignedCaseSubscriptionUpsellModalVisible = true
            }

            const onCloseWithNotification = () => {
              onClose()
              openAddedProductNotification(items, data?.productsAdded)
            }

            openModal('caseSubscriptionUpsellModal', {
              priority: 4,
              onClose: isRedesignedCaseSubscriptionUpsellModalVisible ? onClose : onCloseWithNotification,
              onShow,
            })
          }
        }
      }

      if (!isRedesignedCaseSubscriptionUpsellModalVisible) {
        openAddedProductNotification(items, data?.productsAdded)
      }
    }

    return
  }, [
    queueMinimalLimitRef,
    mutate,
    client.cache,
    isPopupAlreadyTriedThisEnabled,
    isOptInEnabled,
    isLead,
    isHereticCaseUpsellEnabled,
    isStrawberryShortcakeCaseUpsellEnabled,
    isCaseUpsellEnabled,
    isHidePopupsAbEnabled,
    isPopupSubscriptionsRedesign,
  ])

  return [
    action,
    { isFetching },
  ] as const
}

export const useQueueResolvePostponedItems = () => {
  const [ addItemsToQueue ] = useQueueAddItemsBase()

  return useCallback((params: QueueModule.AddItemParams & { withLEP?: boolean, withPriorityProducts?: boolean }) => {
    const { withLEP, withPriorityProducts } = params
    const postponedItems = localStorage.getItem<QueueModule.AddItemsInput>(constants.localStorageNames.queuePendingProducts)

    // we can't add LEP items to users without subscription, so we keep the if we need
    const itemsToAdd = []
    const itemsToKeep = []

    postponedItems?.forEach((item) => {
      if (withLEP || !item.product.isLimitedEdition) {
        itemsToAdd.push(item)
      }
      else {
        itemsToKeep.push(item)
      }
    })

    if (itemsToKeep.length) {
      localStorage.setItem(constants.localStorageNames.queuePendingProducts, itemsToKeep)
    }
    else {
      localStorage.removeItem(constants.localStorageNames.queuePendingProducts)
    }

    // first products in queue
    if (withPriorityProducts) {
      const highPriorityProducts = localStorage.getItem<QueueModule.AddItemsInput>(constants.localStorageNames.queueHighPriorityProducts)
      const lowPriorityProducts = localStorage.getItem<QueueModule.AddItemsInput>(constants.localStorageNames.queueLowPriorityProducts)

      localStorage.removeItem(constants.localStorageNames.queueHighPriorityProducts)
      localStorage.removeItem(constants.localStorageNames.queueLowPriorityProducts)

      highPriorityProducts?.forEach((item) => {
        // because every high priority product has position FIRST, we reverse the adding
        itemsToAdd.unshift(item)
      })

      lowPriorityProducts?.forEach((item) => {
        itemsToAdd.push(item)
      })
    }

    if (!itemsToAdd.length) {
      return Promise.resolve()
    }

    return addItemsToQueue(itemsToAdd, params)
  }, [ addItemsToQueue ])
}

// adding to queue logic for UI
export const useQueueAddItems = () => {
  const history = useHistory()

  const client = useApolloClient()
  const [ addItemsToQueue, state ] = useQueueAddItemsBase()

  const { isLoggedIn } = useUser()
  const { subscription } = useSubscription()

  const isCardUpdateModalVisibleRef = useRef(null)
  isCardUpdateModalVisibleRef.current = subscription?.isUnpaid

  const isResubscribeFlowAvailableRef = useRef(null)
  isResubscribeFlowAvailableRef.current = subscription?.isCancelled

  const isLeadRef = useRef(null)
  isLeadRef.current = !subscription || subscription.hasNeverSubscribed

  const action = useCallback<QueueModule.AddItems>((input, params = {}) => {
    const items = Array.isArray(input) ? input : [ input ]

    // withResubscribeFlow is disabled by default, because
    const { withNonAuthRedirect = true, withResubscribeFlow = false } = params

    if (isLeadRef.current) {
      items.forEach(({ product }) => {
        const { productId, productUid, brand, name, gender, category, productLabels } = product

        track('Lead add to queue', {
          productId,
          productUid,
          productBrand: brand,
          productFullName: `${brand} ${name}`,
          productGender: gender,
          productCategory: category,
          productLabels,
        })
      })
    }

    if (!isLoggedIn) {
      addItemsToPostponed(items)

      if (withNonAuthRedirect) {
        history.push(`${links.register}?redirect=${encodeURIComponent(window.location.pathname + window.location.search)}`)
        return
      }

      return
    }

    if (withResubscribeFlow && isResubscribeFlowAvailableRef.current && items.length === 1) {
      openModal('resubscribeModal', {
        productToAdd: items[0],
      })

      throw new CancelledPromiseError('RESUBSCRIBE_FLOW')
    }

    if (isCardUpdateModalVisibleRef.current) {
      openModal('cardUpdateModal', {
        priority: 1,
        flow: 'Add to queue',
      })

      // We need to invalidate the cart cache to correctly display promo products in cart (PF-1519)
      client.cache.evict({
        id: 'UserData:{}',
        fieldName: 'cart',
      })

      return
    }

    return addItemsToQueue(items, params)
  }, [ addItemsToQueue, client, history, isLoggedIn ])

  return [
    action,
    state,
  ] as const
}

export const useQueueMoveItem = () => {
  const [ mutate, { isFetching, client } ] = useMutation(queueMoveItemQuery, {
    fetchPolicy: 'no-cache',
  })

  const queueMinimalLimitRef = useQueueMinimalLimitRef()

  const moveItem = useCallback<QueueModule.MoveItem>(async (mutationInput) => {
    // for analytics
    // const product = getProductByFlatIndex(queueData.currentUser.data.queue.queueItems, fromFlatIndex)
    const queueInput = {
      limit: queueMinimalLimitRef.current,
    }

    const result = await mutate({
      variables: {
        mutationInput,
        queueInput,
      },
      update: (cache, result) => {
        updateQueueCache(cache, queueInput, result.data.queueMoveItem?.queue)
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { error, queue } = result.data.queueMoveItem

    if (error) {
      // TODO add LEP errors handling - added on 2021-06-16 by maddoger
      throw new UserError(error)
    }

    // We need to invalidate the cart cache to correctly display promo products in cart (PF-1519)
    client.cache.evict({
      id: 'UserData:{}',
      fieldName: 'cart',
    })

    return modifyQueueItems(filterEmptyQueueItems(queue.queueItems))
  }, [ mutate, client, queueMinimalLimitRef ])

  return [
    moveItem,
    { isFetching },
  ] as const
}

export const useQueueUpdateItem = () => {
  const [ mutate, { isFetching, client } ] = useMutation(queueUpdateItemQuery, {
    fetchPolicy: 'no-cache',
  })

  const queueMinimalLimitRef = useQueueMinimalLimitRef()

  const updateItem = useCallback<QueueModule.UpdateItem>(async (mutationInput) => {
    const queueInput = {
      limit: queueMinimalLimitRef.current,
    }

    const result = await mutate({
      variables: {
        mutationInput,
        queueInput,
      },
      update: (cache, result) => {
        updateQueueCache(cache, queueInput, result.data.queueUpdateItem?.queue)
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { error } = result.data.queueUpdateItem

    if (error) {
      // TODO add LEP errors handling - added on 2021-06-16 by maddoger
      if (error.__typename === 'QueueUpdateItemError') {

        if (error.queueUpdateItemErrorCode === 'RESTRICT_SAME_PERFUME') {
          // TODO: Refactor error handling and add analytics — added on 02–04–2022 by algeas
          openModal('sameProductModal')

          throw new CancelledPromiseError()
        }
      }

      throw new UserError(error)
    }

    // We need to invalidate the cart cache to correctly display promo products in cart (PF-1519)
    client.cache.evict({
      id: 'UserData:{}',
      fieldName: 'cart',
    })

    return
  }, [ queueMinimalLimitRef, mutate, client.cache ])

  return [
    updateItem,
    { isFetching },
  ] as const
}

export const useQueueDeleteItem = () => {
  const [ mutate, { isFetching, client } ] = useMutation(queueDeleteItemQuery, {
    fetchPolicy: 'no-cache',
  })

  const queueMinimalLimitRef = useQueueMinimalLimitRef()

  const deleteItem = useCallback<QueueModule.DeleteItem>(async (mutationInput) => {
    const queueInput = {
      limit: queueMinimalLimitRef.current,
    }

    const result = await mutate({
      variables: {
        mutationInput,
        queueInput,
      },
      update: (cache, result) => {
        updateQueueCache(cache, queueInput, result.data.queueDeleteItem?.queue)
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { queue, error } = result.data.queueDeleteItem

    if (error) {
      // TODO add LEP errors handling - added on 2021-06-16 by maddoger
      throw new UserError(error)
    }

    const productsInQueueQty = getProductsInQueueQnt(queue)

    // We need to invalidate the cart cache to correctly display promo products in cart (PF-1519)
    client.cache.evict({
      id: 'UserData:{}',
      fieldName: 'cart',
    })

    client.cache.modify({
      id: 'PersonalInfo:{}',
      fields: {
        analyticsMetadata: ((value) => ({
          ...value,
          productsInQueueQty,
        })),
      },
    })

    return
  }, [ mutate, client, queueMinimalLimitRef ])

  return [
    deleteItem,
    { isFetching },
  ] as const
}
