import React from 'react'
import { cx } from 'helpers'
import { useDevice } from 'device'
import { useEntry } from 'intersection-observer'

import FadeImage from './FadeImage'
import { useImageRotator } from '../hooks/useImageRotator'


const IngredientsImages = () => {
  const { isMobile } = useDevice()

  const [ ref, entry ] = useEntry()

  const images = useImageRotator(entry?.isIntersecting)

  return (
    <div ref={ref} className={cx('grid', isMobile ? 'mt-24 grid-cols-5' : 'rotate-45 grid-cols-4 gap-16')}>
      {images.map((src, index) => <FadeImage src={src} key={index} /> )}
    </div>
  )
}


export default IngredientsImages
