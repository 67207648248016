import { type ApolloClients } from 'apollo-client'
import { constants } from 'helpers'

import type { GrowthBook } from '@growthbook/growthbook-react'
import dumpDataQuery from './dumpData.graphql'


const dumpData = (apolloClient: ApolloClients['default'], gb: GrowthBook<Record<string, any>>) => {
  try {
    // to log only frontend relative items
    const feAbTests: string[] = Object.values(constants.abTests)
    const feFeatures: string[] = Object.values(constants.features)

    const data = apolloClient.cache.readQuery({
      query: dumpDataQuery,
    })

    const activeAbTests = data?.abTests?.data?.abTests.reduce((result, item) => {
      const { enabled, value, name } = item

      if ((enabled || value !== 'a') && feAbTests.includes(name)) {
        result.push(`${name}(${value})`)
      }

      return result
    }, []).sort()

    const activeFeatures = data?.features?.data?.featureToggles.reduce((result, item) => {
      const { enabled, name } = item

      if (enabled && feFeatures.includes(name)) {
        result.push(name)
      }

      return result
    }, []).sort()


    const growthbookFeatures = Object.fromEntries(Object.keys(gb?.getFeatures())
      .map((key) => {
        const userValue = gb.getFeatureValue(key, null)

        return [
          key,
          userValue,
        ]
      })
      .filter(([ _, value ]) => value !== false && value !== null)
      .sort((a, b) => a[0].localeCompare(b[0]))
    )

    return {
      activeAbTests,
      activeFeatures,
      growthbookFeatures,
      id: data?.currentUser?.data?.personalInfo?.id,
      email: data?.currentUser?.data?.personalInfo?.email,
      localePreferences: data?.currentUser?.data?.localePreferences,
      shippingCountry: data?.currentUser?.data?.personalInfo?.addressInfo?.shipping?.country,
    }
  }
  catch (error) {
    return null
  }
}


export default dumpData
