import { useRef } from 'react'
import logger from 'logger'
import useCatalogueStructure from './useCatalogueStructure'

// TODO should be enum from backend - added on 07.01.2022 by sonatskiy

type SubscriptionCatalogueName = 'perfumes' | 'colognes' | 'beauty'
type TheEditCatalogueName =
  | 'theEdit'
  | 'allTheEdit'
  | 'accessoriesTheEdit'
  | 'candlesTheEdit'
  | 'deodorantsTheEdit'
  | 'diffusersTheEdit'
  | 'fragranceTheEdit'
  | 'homeTheEdit'
  | 'bathBodyTheEdit'
  | 'giftSetsTheEdit'
  | 'carTheEdit'
  | 'otherTheEdit'
type SaksCatalogueName =
  | 'SaksWeb'
  | 'SaksAll'
  | 'SaksPerfumes'
  | 'SaksColognes'
  | 'SaksUnisex'
type HolidayCatalogueName =
  'allHoliday'
  | 'All'
  | 'Candles'
  | 'Deodorants'
  | 'Diffusers'
  | 'Other'
  | 'Fragrances'
  | 'GiftSets'
export type GiftSetsCatalogueName =
  | 'giftSetsPerfumes'
  | 'giftSetsColognes'
export type TheShopCatalogueName =
  | 'shop'
  | 'accessories'
  | 'candlesHomeScents'
  | 'scentbirdCases'
  | 'theShopPerfumes'
  | 'theShopColognes'
  | 'theShopAllBathAndBody'
  | 'theShopAllCandlesHomeScents'
export type ScentHoroscopeCatalogueName =
  | 'horoscopeAriesCartFemale'
  | 'horoscopeAriesCartMale'
  | 'horoscopeAriesQueueFemale'
  | 'horoscopeAriesQueueMale'
  | 'horoscopeTaurusCartFemale'
  | 'horoscopeTaurusCartMale'
  | 'horoscopeTaurusQueueFemale'
  | 'horoscopeTaurusQueueMale'
  | 'horoscopeGeminiCartFemale'
  | 'horoscopeGeminiCartMale'
  | 'horoscopeGeminiQueueFemale'
  | 'horoscopeGeminiQueueMale'
  | 'horoscopeCancerCartFemale'
  | 'horoscopeCancerCartMale'
  | 'horoscopeCancerQueueFemale'
  | 'horoscopeCancerQueueMale'
  | 'horoscopeLeoCartFemale'
  | 'horoscopeLeoCartMale'
  | 'horoscopeLeoQueueFemale'
  | 'horoscopeLeoQueueMale'
  | 'horoscopeVirgoCartFemale'
  | 'horoscopeVirgoCartMale'
  | 'horoscopeVirgoQueueFemale'
  | 'horoscopeVirgoQueueMale'
  | 'horoscopeLibraCartFemale'
  | 'horoscopeLibraCartMale'
  | 'horoscopeLibraQueueFemale'
  | 'horoscopeLibraQueueMale'
  | 'horoscopeScorpioCartFemale'
  | 'horoscopeScorpioCartMale'
  | 'horoscopeScorpioQueueFemale'
  | 'horoscopeScorpioQueueMale'
  | 'horoscopeSagittariusCartFemale'
  | 'horoscopeSagittariusCartMale'
  | 'horoscopeSagittariusQueueFemale'
  | 'horoscopeSagittariusQueueMale'
  | 'horoscopeCapricornCartFemale'
  | 'horoscopeCapricornCartMale'
  | 'horoscopeCapricornQueueFemale'
  | 'horoscopeCapricornQueueMale'
  | 'horoscopeAquariusCartFemale'
  | 'horoscopeAquariusCartMale'
  | 'horoscopeAquariusQueueFemale'
  | 'horoscopeAquariusQueueMale'
  | 'horoscopePiscesCartFemale'
  | 'horoscopePiscesCartMale'
  | 'horoscopePiscesQueueFemale'
  | 'horoscopePiscesQueueMale'
export type ShopTravelCatalogueName =
  | 'shopTravelSizePerfumes'
  | 'shopTravelSizeColognes'
export type ShopSampleCatalogueName =
  | 'shopSamplePerfumes'
  | 'shopSampleColognes'
export type Shop20mlMiniBottlesCatalogueName =
  | 'shop20mlMiniBottlesPerfumes'
  | 'shop20mlMiniBottlesColognes'
export type ShopGiftsUnder50CatalogueName =
  | 'giftsUnder50'
  | 'giftsUnder50All'
  | 'giftsUnder50Fragrances'
  | 'giftsUnder50GiftSets'
  | 'giftsUnder50Candles'
  | 'giftsUnder50Diffusers'
  | 'giftsUnder50Other'

export type HolidayShopCatalogueName =
  | 'holidayShop'
  | 'holidayShopForHer'
  | 'holidayShopForHim'
  | 'allHoliday'

// TODO: Split by tree - added on 2024-11-18 by girilloid
export type ConstructorCatalogueName = 'webMainPerfumesSubscription' | 'webMainColognesSubscription'

export type ScentbirdCatalogueName =
| SubscriptionCatalogueName
| TheShopCatalogueName
| TheEditCatalogueName
| SaksCatalogueName
| HolidayCatalogueName
| GiftSetsCatalogueName
| ScentHoroscopeCatalogueName
| ShopSampleCatalogueName
| ShopTravelCatalogueName
| ShopGiftsUnder50CatalogueName
| HolidayShopCatalogueName
| Shop20mlMiniBottlesCatalogueName

export type CatalogueName =
  | ConstructorCatalogueName
  | ScentbirdCatalogueName

export type UseCatalogueIdProps = {
  catalogueName: CatalogueName
  skip?: boolean
}

const useCatalogueId = ({ catalogueName, skip = false }: UseCatalogueIdProps) => {
  const { flatStructure, isFetching } = useCatalogueStructure({ skip })

  const catalogueId = flatStructure?.[catalogueName]
  const isReported = useRef<boolean>(false)

  if (!isFetching && flatStructure && !catalogueId && !isReported.current) {
    logger.warn(`Catalogue "${catalogueName}" is not found in the catalogue structure`)
    isReported.current = true
  }

  const isConstructorCatalogue = typeof catalogueId === 'string'

  const catalogueIdObject = isConstructorCatalogue ? {
    catalogueId: null,
    catalogueKey: catalogueId,
  } : {
    catalogueId,
    catalogueKey: null,
  }

  return {
    ...catalogueIdObject,
    isFetching,
  }
}

export default useCatalogueId
