import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { constants } from 'helpers'
import { useAb } from 'hooks'

import { useSubscription } from 'modules/subscription'
import { useUser } from 'modules/user'


export const useCnstrcRecommendationsEnabled = () => {
  const isConstructorRecommendationsFeatureEnabled = useFeatureIsOn(constants.features.constructorRecommendations)
  const isConstructorRecommendationsSubscribedEnabled = useFeatureIsOn(constants.features.constructorRecommendationsSubscribed)
  const isConstructorRecommendationsActiveLeadEnabled = useFeatureIsOn(constants.features.constructorRecommendationsActiveLead)
  const isConstructorRecommendationsTestEnabled = useAb(constants.abTests.constructorRecommendations) === 'b'

  const { isLoggedIn } = useUser()
  const { subscription } = useSubscription()

  const areConstructorRecommendationsEnabled =
    // Constructor recommendations are not displayed for unauthenticated users
    isLoggedIn
    && isConstructorRecommendationsFeatureEnabled
    // Constructor recommendations are displayed for users with 'b' test value and all leads when corresponding feature is enabled
    && (isConstructorRecommendationsTestEnabled || (isConstructorRecommendationsActiveLeadEnabled && !subscription?.isActive))
    // Constructor recommendations are not displayed for subscribed users when corresponding feature is enabled
    && (isConstructorRecommendationsSubscribedEnabled || !subscription?.isActive)

  return areConstructorRecommendationsEnabled
}
