export const getRandomIndex = (max: number) => Math.floor(Math.random() * max)

export const getRandomUniqueImage = (allImages, currentImages) => {
  const currentImagesSet = new Set(currentImages)
  const restImages = allImages.filter(({ src }) => !currentImagesSet.has(src))

  return restImages[getRandomIndex(restImages.length)].src
}

export const getRandomImages = (allImages, countToShow) => {
  const result = []

  for (let i = 0; i < countToShow; i++) {
    const newImageSrc = getRandomUniqueImage(allImages, result)

    result.push(newImageSrc)
  }

  return result
}
