import { useCallback, useMemo, useRef, createContext, useContext } from 'react'
import { track } from 'analytics'
import { useMutation, useQuery } from 'apollo-client'
import { useIntl } from 'intl'
import {
  CancelledPromiseError,
  constants,
  formatProductLabelsForAnalytics,
  GraphQLError,
  UserError,
  object,
} from 'helpers'
import links from 'links'
import localStorage from 'local-storage'
import logger from 'logger'
import { openModal, isModalOpen } from 'modals'
import { openNotification } from 'notifications'
import { useHistory, useLocationState } from 'router'

import { useUser } from 'modules/user'
import { useSubscription } from 'modules/subscription'
import { useCookieCoupon, useInquireSubscriptionCoupon } from 'modules/coupon'
import { usePriceSelection } from 'modules/priceSelection'

import type {
  CartOperationInput,
  DriftOption,
  EcommercePriceRulesAndOffers,
  ProductLineItem,
  TradingItemVariety,
} from 'typings/graphql'
import type { CartProductFragmentPayload } from './fragments/cartProduct.graphql'
import type { CartProductsFragmentPayload } from './fragments/cartProducts.graphql'
import type { CartLimitedDropItemsFragmentPayload } from './fragments/cartLimitedDropItems.graphql'
import cartQuery, { type CartVariables } from './graph/cart.graphql'

import cartModifyQuery, { type CartModifyVariables } from './graph/cartModify.graphql'
import cartDeleteQuery, { type CartDeleteVariables } from './graph/cartDelete.graphql'
import modifyCart from './modifyCart'


type OpenAddedProductNotificationProps = {
  count?: number
  name: string
  brand: string
  image: string
  rebrandImage: string
  drift?: DriftOption
  variety?: TradingItemVariety
}

const openAddedProductNotification = ({ count = 1, name, brand, image, rebrandImage, drift, variety }: OpenAddedProductNotificationProps) => {
  openNotification('productAdded', {
    to: 'cart',
    count,
    name,
    brand,
    image,
    rebrandImage,
    drift,
    variety,
  })
}

type OpenAddedLimitedDropProductNotificationProps = {
  brand: string
  countdownState?: CartLimitedDropItemsFragmentPayload['countdownState']
  expiredDate?: string
  giftDescription?: string
  image: string
  name: string
}

const openAddedLimitedDropProductNotification = ({
  brand,
  countdownState,
  expiredDate,
  giftDescription,
  image,
  name,
}: OpenAddedLimitedDropProductNotificationProps) => {
  const remainingTime = expiredDate ? new Date(expiredDate).getTime() - Date.now() : 0

  openNotification('limitedDropNotification', {
    brand,
    countdownState: countdownState === 'PRODUCT' ? 'product' : 'sale',
    giftDescription,
    image,
    name,
    remainingTime,
  })
}

const useCartInput = () => {
  const { isLoggedIn, isFetching: isUserFetching } = useUser()
  const { subscription, isFetching: isSubscriptionFetching } = useSubscription()
  const { country, channel } = usePriceSelection()

  const shouldLoadInquireCoupon = isLoggedIn && !subscription?.isActive

  // coupon
  // 1. the main source of truth is the applied coupon. The only way to fetch it is purchaseInquire
  const { inquireSubscriptionCouponCode, isFetching: isAppliedCouponFetching } = useInquireSubscriptionCoupon({
    skip: !shouldLoadInquireCoupon, // we can't fetch it for anonymous users
  })

  // 2. the second source of truth is coupons from cookies. For users whose goes from landings or without any coupon applied
  const { cookieCouponCode } = useCookieCoupon()
  const couponCode = inquireSubscriptionCouponCode || cookieCouponCode

  const cartInputRef = useRef<CartModifyInput['options']>(null)
  cartInputRef.current = {
    couponCode,
    address: {
      country,
    },
    priceSelection: {
      country,
      channel,
    },
  }

  return {
    cartInputRef,
    isCartInputFetching: isUserFetching || isSubscriptionFetching || isAppliedCouponFetching,
  }
}

export const useCart = (input?: CartVariables['input']) => {
  const { cartInputRef, isCartInputFetching } = useCartInput()

  const { data, isFetching } = useQuery(cartQuery, {
    fetchPolicy: 'cache-first',
    ssr: false,
    skip: isCartInputFetching,
    variables: {
      input: object.merge(cartInputRef.current, { purchaseLevel: 'ALL' as const }, input),
    },
  })

  const cart = useMemo(() => {
    const cartData = data?.currentUser?.data?.cart

    if (!cartData) {
      return null
    }

    return modifyCart(cartData)
  }, [ data ])

  return {
    cart,
    isFetching: isCartInputFetching || isFetching,
  }
}

// cart provider to keep cart and cart modify queries
const CartContext = createContext<ReturnType<typeof useCart>>(null)
export const CartContextProvider = CartContext.Provider

// use if you want to use cart inside one component like CartModal
export const useCartContext = () => useContext(CartContext)


type GetModifyCartInputProps = {
  tradingItemId: number
  tradingItemUid: string
  isDrift?: boolean
  isLimitedDrop?: boolean
  isStarterSet?: boolean
  offer?: EcommercePriceRulesAndOffers
  quantity: number
}

const getModifyCartInput = (props: GetModifyCartInputProps): CartOperationInput => {
  const {
    tradingItemId, tradingItemUid, isDrift, isLimitedDrop, isStarterSet, offer, quantity,
  } = props

  if (isLimitedDrop) {
    if (tradingItemUid) {
      return {
        limitedDropItems: [
          {
            tradingItemUid,
            quantity,
          },
        ],
      }
    }

    logger.error({ tradingItemId }, 'getModifyCartInput is called without tradingItemUid for limited drop product')

    return {
      limitedDropItems: [
        {
          tradingItemId,
          quantity,
        },
      ],
    }
  }

  if (isDrift) {
    if (tradingItemUid) {
      return {
        driftItems: [
          {
            tradingItemUid,
            quantity,
            starterSet: isStarterSet,
          },
        ],
      }
    }

    logger.error({ tradingItemId }, 'getModifyCartInput is called without tradingItemUid for drift product')

    return {
      driftItems: [
        {
          tradingItemId,
          quantity,
          starterSet: isStarterSet,
        },
      ],
    }
  }

  if (tradingItemUid) {
    return {
      productItems: [
        {
          productLineItemUid: tradingItemUid,
          quantity,
          offer,
        },
      ],
    }
  }

  logger.error({ tradingItemId }, 'getModifyCartInput is called without tradingItemUid for regular product')

  return {
    productItems: [
      {
        productLineItemId: tradingItemId,
        quantity,
        offer,
      },
    ],
  }
}

type LimitedDropCartItem = CartLimitedDropItemsFragmentPayload
type ProductCartItem = CartProductsFragmentPayload
type CartItem = LimitedDropCartItem | ProductCartItem

type FindActiveProductProps = {
  cartItems: CartItem[]
  isStarterSet?: boolean
  tradingItemId: number
  tradingItemUid: string
}

const findActiveProduct = ({ cartItems, tradingItemId, tradingItemUid, isStarterSet }: FindActiveProductProps): CartItem => {
  return cartItems?.find((item) => {
    const { id, uid } = item
    const isLimitedDrop = item.__typename === 'LimitedDropLineItem'

    const itemStarterSet = isLimitedDrop ? false : (item.drift?.starterSet || false)

    return (tradingItemUid ? uid === tradingItemUid : id === tradingItemId) && itemStarterSet === isStarterSet
  })
}

// this hook handles business logic when user add something to the cart
export const useCartAddProduct = () => {
  const history = useHistory()
  const intl = useIntl()
  const { currentUrl } = useLocationState()
  const { isLoggedIn } = useUser()

  const [ modifyCart, state ] = useCartModify()
  const apolloClient = state.client

  const currentUrlRef = useRef(currentUrl)
  currentUrlRef.current = currentUrl

  const submit = useCallback<CartModule.AddProduct>(async (input, params = {}) => {
    const {
      product: {
        productUid,
        tradingItemId,
        tradingItemUid,
        quantity = 1,
        forceQuantity = false,
        offer,
        name,
        brand,
        image,
        rebrandImage,
        isSubscribedToStockNotification,
        isLimitedDrop,
        isDrift,
        isStarterSet,
      },
      metadata,
    } = input

    if (!isLoggedIn && isLimitedDrop) {
      return history.push(`${links.login}?redirect=${encodeURIComponent(currentUrlRef.current)}`)
    }

    const { withNotification = true, withErrorModals = true, withRedirectToCheckout = false, withCartOpen = false } = params

    // ATTN works only with ecommerce products and ALL purchase level
    const cartInfo = apolloClient.cache.readQuery({
      query: cartQuery,
      variables: {
        input: {
          purchaseLevel: 'ALL',
        },
      },
    })

    const cartItems: CartItem[] = isLimitedDrop ? cartInfo?.currentUser?.data?.cart?.limitedDropItems : cartInfo?.currentUser?.data?.cart?.products
    const productInfo = findActiveProduct({
      tradingItemId,
      tradingItemUid,
      cartItems,
      isStarterSet,
    })

    // backend doesn't add items to the cart, it modifies the cart, so we need to count the amount of products manually
    const currentQuantity = productInfo?.quantity || 0

    const modifyCartInput = getModifyCartInput({
      tradingItemId,
      tradingItemUid,
      quantity: forceQuantity ? quantity : currentQuantity + quantity,
      isDrift,
      isLimitedDrop,
      isStarterSet,
      offer,
    })

    const { data: newCartInfo, error } = await modifyCart({
      ...modifyCartInput,
      metadata,
    })

    const addedCartItems: CartItem[] = isLimitedDrop ? newCartInfo?.limitedDropItems : newCartInfo?.products
    const addedProductInfo = findActiveProduct({
      tradingItemId,
      tradingItemUid,
      cartItems: addedCartItems,
      isStarterSet,
    })

    if (error && withErrorModals) {
      if (error.__typename === 'CartError') {
        if (error.cartErrorCode === 'OUT_OF_STOCK') {
          openModal('outOfStockModal', {
            productUid,
            isSubscribedToStockNotification,
          })
          throw new CancelledPromiseError()
        }
      }

      throw new UserError(error)
    }

    if (withNotification && !withRedirectToCheckout) {
      if (isLimitedDrop) {
        const { countdownState, expiredDate, giftProducts } = addedProductInfo as LimitedDropCartItem

        openAddedLimitedDropProductNotification({
          brand,
          countdownState,
          expiredDate,
          giftDescription: giftProducts?.[0]?.title,
          image,
          name,
        })
      }
      else {
        const { drift, variety } = addedProductInfo as ProductCartItem

        openAddedProductNotification({
          brand,
          drift,
          image,
          name,
          rebrandImage,
          variety,
        })
      }
    }

    if (withCartOpen) {
      openCartModal({
        flow: 'Add to cart button',
      })
    }

    if (withRedirectToCheckout) {
      history.push(links.checkout)
    }

    const productLabels = formatProductLabelsForAnalytics({ product: addedProductInfo?.product, intl })

    const productCategory = addedProductInfo?.product.category.toLowerCase()
    // TODO: Remove after back implementation of metadata storage — added on 07–07–2022 by algeas
    const storedMetadata = localStorage.getItem(constants.localStorageNames.lastAddedToCartProductMetadata)
    const page = metadata.pageType === 'Product' ? `The Shop ${productCategory} product` : metadata.page
    const hasTheEditProducts = storedMetadata?.hasTheEditProducts || addedProductInfo?.product.theEdit

    const metadataToStore = {
      ...metadata,
      page,
      hasTheEditProducts,
    }

    track('Add to cart front', {
      page,
      productLabels,
      placement: metadata.placement,
      productPrice: Number(intl.formatPrice(addedProductInfo?.price, { withCurrency: false })),
      productId: addedProductInfo?.product.id,
      productUid: addedProductInfo?.product.uid,
      productGender: addedProductInfo?.product.gender,
      productVolume: addedProductInfo?.product ? `${addedProductInfo?.product.volume} ${addedProductInfo?.product.unit}` : undefined,
      productFullName: addedProductInfo?.product.fullName || `${addedProductInfo?.product.brandInfo.name} ${addedProductInfo?.product?.name}`,
      productCategory: addedProductInfo?.product.category,
      productBrand: addedProductInfo?.product.brandInfo.name,
      isTheEdit: addedProductInfo?.product?.theEdit,
    })

    localStorage.setItem(constants.localStorageNames.lastAddedToCartProductMetadata, metadataToStore)
  }, [ modifyCart, apolloClient, history, isLoggedIn, intl ])

  return [
    submit,
    state,
  ] as const
}

export type CartModifyInput = CartModifyVariables['input']

export const useCartModify = () => {
  const { cartInputRef } = useCartInput()
  const [ mutate, { isFetching, client } ] = useMutation(cartModifyQuery)

  const submit = useCallback(async (input: CartModifyInput) => {
    const result = await mutate({
      variables: {
        input: {
          ...input,
          options: object.merge(cartInputRef.current, { purchaseLevel: 'ALL' as const }, input.options),
        },
      },
      // manual cache update
      fetchPolicy: 'no-cache',
      update: (cache, result, options) => {
        if (result.data.cartModify.data) {
          cache.writeQuery({
            query: cartQuery,
            variables: { input: options.variables.input.options },
            data: {
              currentUser: {
                __typename: 'UserPayload',
                data: {
                  __typename: 'UserData',
                  cart: result.data.cartModify.data.cart,
                },
                error: null,
              },
            },
          })
        }
      },
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.cartModify

    return {
      error,
      data: !error ? modifyCart(data.cart) : null,
    }
  }, [ mutate, cartInputRef ])

  return [
    submit,
    {
      client,
      isFetching,
    },
  ] as const
}

export type CartDeleteInput = CartDeleteVariables['input']

export const useCartDelete = () => {
  const { cartInputRef } = useCartInput()
  const [ mutate, { isFetching } ] = useMutation(cartDeleteQuery)

  const submit = useCallback(async (input: CartDeleteInput) => {
    const result = await mutate({
      variables: {
        input: {
          ...input,
          options: object.merge(cartInputRef.current, { purchaseLevel: 'ALL' as const }, input.options),
        },
      },
      update: (cache, result, options) => {
        cache.writeQuery({
          query: cartQuery,
          variables: { input: options.variables.input.options },
          data: {
            currentUser: {
              __typename: 'UserPayload',
              data: {
                __typename: 'UserData',
                cart: result.data.cartDelete.data.cart,
              },
              error: null,
            },
          },
        })
      },
      // manual cache update
      fetchPolicy: 'no-cache',
    })

    if (result.errors) {
      throw new GraphQLError(result.errors)
    }

    const { data, error } = result.data.cartDelete

    if (error) {
      throw new UserError(error)
    }

    return modifyCart(data.cart)
  }, [ mutate, cartInputRef ])

  return [
    submit,
    {
      isFetching,
    },
  ] as const
}

export const openCartModal = (params: CartModule.CartModalProps) => {
  // open modal, only if it isn't opened
  if (!isModalOpen('cartModal')) {
    openModal('cartModal', params)
  }
}
