import React from 'react'
import { useDevice } from 'device'
import { useIntl } from 'intl'

import { Href } from 'components/navigation'
import { Icon } from 'components/dataDisplay'
import type { IconColor } from 'components/dataDisplay'

import messages from './messages'


type LogoProps = {
  className?: string
  to?: string
  color: IconColor
  onClick?: () => void
}

const Logo: React.FunctionComponent<LogoProps> = (props) => {
  const { className, to, color, onClick } = props
  const { isMobile } = useDevice()
  const intl = useIntl()

  const icon = (
    <Icon
      name={isMobile ? 'other/scentbird-logo-small' : 'other/scentbird-logo-medium'}
      color={color}
      ariaLabel="Scentbird Logo"
    />
  )

  if (to) {
    return (
      <Href className={className} to={to} onClick={onClick} aria-label={intl.formatMessage(messages.mainPage)}>
        {icon}
      </Href>
    )
  }

  return (
    <span className={className}>
      {icon}
    </span>
  )
}


export default Logo
