import React from 'react'
import cx from 'classnames'
import { Button } from 'components/inputs'
import { Text } from 'components/dataDisplay'

import HolidayBaseBanner from '../HolidayBaseBanner/HolidayBaseBanner'

import useHolidaySeasonBanner from './util/useHolidaySeasonBanner'
import messages from './util/messages'


type HolidaySeasonBannerProps = {
  discount?: number
  position?: number
  'data-testid'?: string
}

const HolidaySeasonBanner: React.FC<HolidaySeasonBannerProps> = (props) => {
  const { discount, position, 'data-testid': dataTestId = 'holidaySeasonBanner' } = props

  const { link, textMessage, textStyle, textClassName, withBgImage } = useHolidaySeasonBanner({ discount })

  return (
    <HolidayBaseBanner
      position={position}
      to={link}
      withBgImage={withBgImage}
      data-testid={dataTestId}
    >
      <div className={cx('flex h-full items-center justify-center gap-16 px-16', textClassName)}>
        <Text
          className="flex-1 text-white"
          message={textMessage}
          style={textStyle}
          html
        />
        <Button className="px-8" size={38} noPadding title={messages.button} />
      </div>
    </HolidayBaseBanner>
  )
}

export default HolidaySeasonBanner
