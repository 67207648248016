import { useMemo } from 'react'
import cookieStorage from 'cookie-storage'
import logger from 'logger'
import { updateAnalyticsContext } from 'analytics'
import { useOnce } from 'hooks'

import useAdditionalData from './useAdditionalData'


// backend has different from default ISO milliseconds format: yyyy-MM-dd'T'HH:mm:ss.SSSSSSZ
const fixTimeISOString = (date?: string) => date ? new Date(date).toISOString() : undefined

// this hook fills analytics context
const useAnalyticsContextSetup = ({ user, subscription, country }: AnalyticsSetup.HookProps) => {
  const firstVisitDate = useMemo(() => {
    try {
      const firstVisitTimestamp = cookieStorage.getItem<number>('firstVisitTimestamp')
      return (new Date(firstVisitTimestamp * 1000)).toISOString()
    }
    catch (err) {
      return new Date().toISOString()
    }
  }, [])

  const { hasCandleSubscription, hasCaseSubscription, hasDriftSubscription, hasSamplesSubscription, subscriptionDate } = subscription || {}

  const lastSubscriptionDate = fixTimeISOString(subscriptionDate)
  const analyticsMetadata = user?.analyticsMetadata
  const registrationDate = fixTimeISOString(user?.registrationDate)
  const additionalData = useAdditionalData(Boolean(user?.id))

  const properties = useMemo<AnalyticsSetup.SuperProperties>(() => {
    if (!__CLIENT__) {
      return null
    }

    const subscriptionPlan = analyticsMetadata?.subscriptionPlan
    const subscriptionStatus = analyticsMetadata?.subscriptionStatus || 'LEAD'
    const lifetimeStatus = analyticsMetadata?.lifetimeStatus || 'UNREGISTERED'
    const hasScentProfile = analyticsMetadata?.hasScentProfile
    const isGiftSubscription = analyticsMetadata?.isGiftSubscription
    const lifeTimeRevenue = analyticsMetadata?.lifeTimeRevenue
    const firstSubscriptionDate = fixTimeISOString(analyticsMetadata?.firstSubscriptionDate)
    const productsInQueueQty = analyticsMetadata?.productsInQueueQty

    // Apple Pay
    const isApplePayAvailable = Boolean(window.ApplePaySession)
    let canMakeApplePayPayments = false
    try {
      if (isApplePayAvailable && typeof window.ApplePaySession.canMakePayments === 'function') {
        canMakeApplePayPayments = window.ApplePaySession.canMakePayments()
      }
    }
    catch (error) {
      logger.error(error, 'ApplePay canMakePayments error')
    }

    const props: AnalyticsSetup.SuperProperties = {
      country,
      firstVisitDate,
      firstVisitDateTime: firstVisitDate, // required by pageview schema
      registrationDate,
      subscriptionStatus,
      subscriptionPlan,
      lifetimeStatus,
      lifeTimeRevenue,
      hasScentProfile,
      isGiftSubscription,
      firstSubscriptionDate,
      lastSubscriptionDate,
      subscriptionDateTime: lastSubscriptionDate, // required by pageview schema
      productsInQueueQty,
      hasCaseSubscription,
      hasCandleSubscription,
      hasDriftSubscription,
      'has1.5mLSubscription': hasSamplesSubscription,
      scentProfile: additionalData.scentProfile,
      fragranceFamily: additionalData.fragranceFamily,
      isApplePayAvailable,
      canMakeApplePayPayments,
    }

    Object.keys(props).forEach((name) => {
      if (props[name] === undefined || props[name] === null) {
        delete props[name]
      }
    })

    return props
  }, [
    country,
    firstVisitDate,
    lastSubscriptionDate,
    analyticsMetadata,
    registrationDate,
    hasCaseSubscription,
    hasCandleSubscription,
    hasDriftSubscription,
    hasSamplesSubscription,
    additionalData,
  ])

  useOnce(() => {
    if (__CLIENT__) {
      updateAnalyticsContext(properties)
    }
  }, [ properties ])

  return properties
}


export default useAnalyticsContextSetup
