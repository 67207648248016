import React, { useState, useCallback, forwardRef } from 'react'
import { Field, useFieldState } from 'formular'
import { useCombinedRefs, useUniqueId } from 'hooks'
import { Message, useIntl } from 'intl'
import cx from 'classnames'
import { getGlobalHtmlAttrs } from 'helpers/getters'
import type { GlobalHTMLAttrs } from 'helpers/getters'

import { Icon } from 'components/dataDisplay'

import InputNote from '../InputNote/InputNote'
import InputError from '../InputError/InputError'

import s from './Select.module.css'
import messages from './messages'


export const sizes = [ 48, 56 ] as const

export type SelectSize = typeof sizes[number]

export type SelectValue = string

export type SelectOption = {
  title: string
  value: string
}

export type SelectProps = GlobalHTMLAttrs<'HTMLSelectElement'> & {
  className?: string
  id?: string
  field: Field<string>
  size: SelectSize
  label: Intl.Message | string
  options: SelectOption[]
  note?: Intl.Message | string
  onFocus?: React.FocusEventHandler<HTMLSelectElement>
  onBlur?: React.FocusEventHandler<HTMLSelectElement>
  onChange?: (value: SelectValue) => void
}

const Select: React.FunctionComponent<SelectProps> = forwardRef((props, ref) => {
  const {
    className, id, field, size, label, options, note,
    onFocus, onBlur, onChange,
    'data-testid': dataTestId = 'input',
    ...otherProps
  } = props

  const combinedRef = useCombinedRefs([ ref, field.props.ref ])
  const intl = useIntl()
  const [ isFocused, setFocusedState ] = useState(false)

  const { value, error } = useFieldState<string>(field)

  const handleFocus = useCallback((event) => {
    setFocusedState(true)

    if (typeof onFocus === 'function') {
      onFocus(event)
    }
  }, [ onFocus ])

  const handleBlur = useCallback((event) => {
    setFocusedState(false)

    if (typeof onBlur === 'function') {
      onBlur(event)
    }
  }, [ onBlur ])

  const handleChange = useCallback((event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value

    field.set(value)

    if (typeof onChange === 'function') {
      onChange(value)
    }
  }, [ field, onChange ])

  const uniqueId = useUniqueId('select-')
  const controlId = id || uniqueId

  const isFilled = value !== '' && value !== null
  const isErrored = Boolean(error)

  const htmlAttrs = getGlobalHtmlAttrs<GlobalHTMLAttrs<'HTMLSelectElement'>>(otherProps)

  const rootClassName = cx(className, s.root, s[`size-${size}`], {
    [s.focused]: isFocused,
    [s.filled]: isFilled,
    [s.errored]: isErrored,
  })

  const withLabel = Boolean(label)

  return (
    <div className={rootClassName}>
      <div className={cx(s.selectContainer, 'border-solid-gray-30 relative rounded bg-white')}>
        {
          withLabel && (
            <label className={s.label} htmlFor={controlId}>
              <Message value={label} />
            </label>
          )
        }
        <Icon className={s.arrow} name="32/arrow-down" aria-label={intl.formatMessage(messages.iconLabel)} />
        <select
          {...htmlAttrs}
          ref={combinedRef}
          id={controlId}
          className={cx(s.select, withLabel ? s.selectWithLabel : s.selectNoLabel)}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          aria-invalid={isErrored}
          data-testid={dataTestId}
        >
          {
            !value && (
              <option value={null} />
            )
          }
          {
            options.map(({ title, value }) => (
              <option key={value} value={value}>{title}</option>
            ))
          }
        </select>
      </div>
      {
        Boolean(note && !isErrored) && (
          <InputNote
            className="mt-4"
            message={note}
            data-testid={`${dataTestId}Note`}
          />
        )
      }
      {
        isErrored && (
          <InputError
            className="mt-4"
            message={error}
            data-testid={`${dataTestId}Error`}
          />
        )
      }
    </div>
  )
})

Select.displayName = 'Select'


export default React.memo(Select)
