import React, { createContext, useContext } from 'react'
import type { ApolloClient, NormalizedCacheObject } from '@apollo/client'


export type CacheInterface = Server.Instance['cache']

export type CustomApolloClient = ApolloClient<NormalizedCacheObject> & {
  setCache?: (cache: CacheInterface) => void
}

// we use multiple-clients provider instead of default apollo provider
export type ApolloClients = {
  default: CustomApolloClient
  strapi: CustomApolloClient
}

export type ApolloClientName = keyof ApolloClients

const ApolloClientsContext = createContext<ApolloClients>(null)

export const useApolloClient = (name: ApolloClientName = 'default') => {
  const clients = useContext(ApolloClientsContext)

  if (!clients) {
    throw new Error('Could not find "clients". Wrap the root in ApolloClientsProvider')
  }

  if (!clients[name]) {
    throw new Error(`Apollo client "${name}" not found`)
  }

  return clients[name]
}

// provider
type ApolloClientsProviderProps = {
  children?: React.ReactNode
  clients: ApolloClients
}

export const ApolloClientsProvider: React.FC<ApolloClientsProviderProps> = ({ children, clients }) => (
  <ApolloClientsContext.Provider value={clients}>
    {children}
  </ApolloClientsContext.Provider>
)
