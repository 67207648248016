import { buildTransport, type Level, composeError } from '@scentbird/logger'


const methodMap: Record<Level, any> = {
  fatal: console.error,
  error: console.error,
  warn: console.warn,
  info: console.info,
  debug: console.debug,
  trace: console.log,
}


export const consoleTransport = buildTransport((source) => {
  const { time, level, message, error, ...rest } = source

  const method = methodMap[level] || console.log
  const args = colorizeMessage(message)

  if (error) {
    const composedError = composeError(error)
    args.push('\n', composedError)

    if (composedError.cause) {
      args.push('\n\nCaused by', composedError.cause)
    }
  }

  if (Object.keys(rest).length > 0) {
    args.push(rest)
  }

  method(...args)
}, { parse: true })


const analyticsStyle = 'font-weight: bold; color: #0000FF;'

const colorizeMessage = (message: string): any[] => {
  if (typeof message === 'string') {
    const match = message.match(/(\[(Mixpanel|DataLayer|Iterable|Attentive|Rudderstack|Analytics)])( (track|page) (.*)|)/)

    if (match) {
      if (match[3].length) {
        // track case
        return [
          message.replace(match[0], `%c${match[1]} %c${match[4]} %c${match[5]}`),
          analyticsStyle,
          '',
          'font-weight: bold;',
        ]
      }

      return [
        message.replace(match[0], `%c${match[1]}%c`),
        analyticsStyle,
        '',
      ]
    }
  }

  if (message) {
    return [ message ]
  }

  return []
}

