import React from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import { useIntl } from 'intl'

import { getAddItemProduct } from 'helpers'

import type { CartOperationInput } from 'typings/graphql'

import { Text } from 'components/dataDisplay'
import { buttonMessages } from 'components/inputs'
import { Href } from 'components/navigation'

import AddToButton from 'compositions/buttons/AddToButton/AddToButton'
import type { AddToButtonBaseProps } from 'compositions/buttons/AddToButton/components/AddToButtonBase/AddToButtonBase'
import ProductPrice from 'compositions/ProductPrice/ProductPrice'
import ProductImage from 'compositions/ProductImage/ProductImage'

import Brand from '../components/Brand/Brand'
import Labels from '../components/Labels/Labels'


export type ProductData = TradingItemFragment.LimitedDrop & {
  productInfo: ProductFragment.BaseLimitedDrop | ProductFragment.DetailedLimitedDrop
}

const bgColorToClassName = {
  'gray-10': 'bg-gray-10',
  'light-beige': 'bg-light-beige',
} as const

type BgColor = keyof typeof bgColorToClassName

const EXCLUDED_LABELS = [ 'NEW_ARRIVAL' as const, 'LIMITED_EDITION' as const ]

export const cartButtonProps = {
  type: 'cart' as const,
  title: buttonMessages.addToCart,
}

export const outOfStockButtonProps = {
  type: 'custom' as const,
  title: buttonMessages.soldOut,
  disabled: true,
  metadata: {},
}

export type ProductCardLimitedDropProps = {
  className?: string
  data: ProductData
  bgColor?: BgColor
  buttonProps: AddToButtonBaseProps & { metadata: CartOperationInput['metadata'] }
  link?: string
  withGenderLabels?: boolean
  withoutLink?: boolean
  withoutButton?: boolean
  onLinkClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

const ProductCardLimitedDrop: React.FunctionComponent<ProductCardLimitedDropProps> = (props) => {
  const { className, bgColor = 'gray-10', buttonProps, data, link, onLinkClick, withGenderLabels = true, withoutButton, withoutLink } = props

  const { image, prices, limitedEditionInfo, productInfo, volume } = data
  const { brandInfo: { name: brand }, giftProducts, name, rebrandLabels } = productInfo

  const { isMobile } = useDevice()
  const intl = useIntl()

  const addToButtonBaseProps = limitedEditionInfo?.productsRemainingCount > 0 ? cartButtonProps : outOfStockButtonProps
  const addToButtonProps = {
    ...addToButtonBaseProps,
    product: getAddItemProduct({
      product: productInfo as any,
      tradingItem: data,
      intl,
    }),
  }

  const giftDescription = giftProducts?.[0]?.title

  const isButtonVisible = !withoutButton && Boolean(buttonProps)

  const productName = volume?.unit && volume?.unit !== 'pcs' ? `${name} · ${volume?.volume} ${volume?.unit}` : name

  const rootClassName = cx(
    className,
    'bg-gray-10 relative flex flex-col justify-between p-12 text-center',
    bgColorToClassName[bgColor],
    !isButtonVisible && 'pb-32'
  )

  const content = (
    <>
      <Labels
        labels={rebrandLabels}
        excludeLabels={EXCLUDED_LABELS}
        isEcommerce
        isSmallCard
        withGenderLabels={withGenderLabels}
      />
      <div className="flex-auto">
        <ProductImage
          src={image}
          bgColor={bgColor}
          lazy={false}
          remWidth={isMobile ? 160 : 320}
        />
        <Brand className="mt-8 line-clamp-2 break-words" brand={brand} style="h8" color="gold-50" />
        <Text className="mt-4 line-clamp-2 break-words" message={productName} style="p4" data-testid="productName" />
        {
          Boolean(giftDescription) && (
            <Text className="line-clamp-2 break-words" message={giftDescription} style="p5" />
          )
        }
      </div>
      <ProductPrice className="mt-12" data={prices} />
    </>
  )

  return (
    <div className={rootClassName} data-testid="productCardLimitedDrop">
      {!withoutLink && link ? (
        <Href className="flex-auto" to={link} draggable={false} onClick={onLinkClick}>
          {content}
        </Href>
      ) : content}
      {
        isButtonVisible && (
          <AddToButton
            className="mt-20"
            size={38}
            fullWidth
            {...addToButtonProps}
            {...buttonProps}
          />
        )
      }
    </div>
  )
}


export default React.memo(ProductCardLimitedDrop)
